import React from "react";
import PropTypes from "prop-types";
import makeStyles from '@mui/styles/makeStyles';
// import classnames from "classnames";
import CircularProgress from "@mui/material/CircularProgress";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(
  (theme) => ({
    container: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      [theme.breakpoints.up("md")]: {
        height: "100%",
      },
      [theme.breakpoints.down('xl')]: {
        height: "100vh",
        marginTop: "-3em",
      },
    },
    icon: {
      width: "9em",
      height: "9em",
    },
    message: {
      textAlign: "center",
      fontFamily: "Roboto, sans-serif",
      opacity: 0.5,
      margin: "0 1em",
    },
  }),
  { name: "RaLoading" }
);

const Loading = (props) => {
  const { t } = useTranslation();
  const {
    // className,
    loadingPrimary = "ra.page.loading",
    loadingSecondary = "ra.message.loading",
  } = props;
  const classes = useStyles(props);
  // const translate = useTranslate();
  return (
    // <div className={classnames(classes.container, className)}>
    <div className={classes.container}>
      <div className={classes.message}>
        <CircularProgress className={classes.icon} color="primary" />
        <h1>{t(loadingPrimary)}</h1>
        <div>{t(loadingSecondary)}.</div>
      </div>
    </div>
  );
};

Loading.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  loadingPrimary: PropTypes.string,
  loadingSecondary: PropTypes.string,
};

Loading.defaultProps = {
  loadingPrimary: "ra.page.loading",
  loadingSecondary: "ra.message.loading",
};

export default Loading;
