import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import makeStyles from "@mui/styles/makeStyles";
import Grid from "@mui/material/Grid";
import { Alert, AlertTitle } from '@mui/material';
import { useStickyState } from "../../../utils/useStickyState";
import Loading from "../../misc/Loading";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function ForgotPassword(props) {
  const classes = useStyles();
  const { t } = useTranslation();

  const [emailField, setEmailField] = useStickyState("", "email");
  const [isLoaded, setLoaded] = useState(true);

  const [error, setError] = useState({
    title: "Error",
    text: "Error happened...",

    display: false,
  });

  const submit = (e) => {
    alert("Function doesn't work at the moment " + e);
    try {
      fetch(
        process.env.REACT_APP_API_URL +
          "sendEmailVerificationCode/" +
          emailField.trim()
      )
        .then((response) => {
          setLoaded(true);
          return response.json();
        })
        .then((data) => {
          console.log("data: ", data)

          alert(t("CheckYourEmail"));
        });
    } catch (fetchError) {
      setError({
        title: "Error happened",
        text: "Sorry. Failed to send password. Error: " + fetchError.message,
        display: true
      });
    }    
  };

  if (!isLoaded) return <Loading />;

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {t("ForgotPassword")}
        </Typography>

        <Grid
          item
          xs={12}
          style={{
            display: error.display ? "" : "none",
            paddingTop: "20px",
            widht: "100%",
          }}
        >
          <Alert severity="error" style={{ widht: "100%" }}>
            <AlertTitle>{error.title}</AlertTitle>
            {error.text}
          </Alert>
        </Grid>
        <div className={classes.form}>
          <TextField
            // variant="outlined"
            variant="standard"
            margin="normal"
            required
            fullWidth
            id="email"
            label={t("E-mail")}
            name="email"
            autoComplete="email"
            autoFocus
            value={emailField}
            onChange={(e) => setEmailField(e.target.value)}
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={(e) => submit(e)}
          >
            {t("Send password to my E-mail")}
          </Button>
        </div>
      </div>
    </Container>
  );
}
