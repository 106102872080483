import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useTranslation } from "react-i18next";

export default function DialogAlert(props) {
  const { t } = useTranslation();
  return (
    <Dialog
      open={props.show}
      onClose={props.close}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {props.text ? props.text : ""}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.disagree} color="primary">
          {t("Disagree")}
        </Button>

        <Button onClick={props.agree} color="primary" autoFocus>
          {t("Agree")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
