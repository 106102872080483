import React, { useState, useEffect } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import PropTypes from "prop-types";

import Uppy from "@uppy/core";
import { Dashboard } from "@uppy/react";
import XHRUpload from '@uppy/xhr-upload'
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import AwsS3Multipart from '@uppy/aws-s3-multipart'
// import "@uppy/file-input/dist/style.css";

import { useTranslation } from "react-i18next";

import Error from "../misc/Error";
import Loading from "../misc/Loading";

const S3Uploader = (props) => {
  var { t } = useTranslation();
  
  let [searchParams] = useSearchParams(useLocation().search);
  let [projectId] = useState(searchParams.get("id"))
  // const [projectId, setProjectId] = useState(
  //   props.location.search.substring(4)
  // );
  // console.log("project_id", projectId);

  // const dataProvider = useDataProvider();
  //   const dispatch = useDispatch();
  //   const redirect = useRedirect();
  //   const notify = useNotify();
  // const [nothing2upload, setNothing2upload] = useState(true);
  const [uploadTree, setUploadTree] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  /* used only for SE360 */
  const [stories, setStories] = useState([]);

  //const AwsS3Multipart = require("@uppy/aws-s3-multipart");

  const [files, setFiles] = useState([]);

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  useEffect(() => {
    setLoading(true);

    const email = JSON.parse(window.localStorage.getItem("email"));
    const code = JSON.parse(window.localStorage.getItem("verification_code"));

    fetch(`${process.env.REACT_APP_API_URL}getUploadTree/${projectId}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Basic " + btoa(email + ":" + code),
      },
    })
      .then((response) => {
        // console.log("getUploadTree.onSuccess", response);
        // setLoading(false);
        return response.json();
      })
      .then((data) => {
        // console.log("getUploadTree.then.data", data);
        setUploadTree(data);
      })
      .catch((e) => {
        // console.log("getUploadTree.catch.error", e);
        setError(e);
        // setLoading(false);
      })
      .finally(() => {
        // console.log("getUploadTree.finally", error);
        setLoading(false);
      });
  }, []);

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;
  // console.log("uploadTree1", uploadTree);
  if (!uploadTree || !uploadTree.items || uploadTree.items.size < 1) {
    // TODO: duplicated code1
    console.log("uploadTree1", uploadTree);
    return (
      <div>
        <center>
          <h1>{t("No need to upload anything for this order")}</h1>
        </center>
      </div>
    );
  } else {
    /* count how many items(Upload spots) to show */
    var cnt = 0;
    uploadTree.items.map((t) =>
      t.children
        .filter((cld1) => cld1.name === "UnProcessed")
        .map((child1) =>
          child1.children
            .filter(
              (cld2) => cld2.name === "Floorplan" && cld2.type === "UnProcessed"
            )
            .map((child2) => {
              cnt++;
              return null;
              // return (
              //   <div>
              //     {child2.id} {child2.workOrderId} {child2.dataSet}{" "}
              //     {child2.name} {child2.type} {child2.uploadState}
              //   </div>
              // );
            })
        )
    );

    if (cnt === 0) {
      // TODO: duplicated code1
      console.log("uploadTree2", uploadTree);
      return (
        <div>
          <center>
            <h1>{t("No need to upload anything for this order")}</h1>
          </center>
        </div>
      );
    }
  }

  //   console.log("uploadTree2");
  // console.log("uploadTree1", uploadTree);
  return (
    <div>
      {uploadTree.items.map((t) => (
        <div key={t.id}>
          {/* ---1---id:{t.id} name:{t.name} address:{t.address} appointmentDate:
          {t.appointmentDate} assignmentDate:{t.assignmentDate}{" "} */}
          <div>
            {t.children
              .filter((cld1) => cld1.name === "UnProcessed")
              .map((child1) => {
                return (
                  <div key={child1.id}>
                    {/* ---2---id:{child1.id} name:{child1.name} */}
                    <div>
                      {child1.children
                        .filter(
                          (cld2) =>
                            cld2.name === "Floorplan" &&
                            cld2.type === "UnProcessed"
                        )
                        .map((child2) => {
                          // setNothing2upload(false);
                          const path = `${projectId}_${child2.orderId}_${child2.name}_${child2.workOrderId}_unProcessed`;
                          // console.log(t.id, child1.id, child2.id, path)
                          const uppy2 = new Uppy({
                            id: "uppy2" + child2.id,
                            // meta: { type: "avatar" },
                            debug: true,
                            autoProceed: false,
                            restrictions: {
                              maxFileSize: 50000000,
                              maxNumberOfFiles: 30,
                              minNumberOfFiles: 1,
                              allowedFileTypes: [".pdf", ".jpg", ".jpeg"],
                              // allowedFileTypes: ["image/*", "video/*"],
                            },
                          });
                          uppy2.setMeta({
                            path: path,
                          });

                          // TODO: may somebody can came up with better idea how to do this final confirmation call?
                          if (process.env.REACT_APP_API_URL.includes('zentuvowebshop')) 
                          {
                            // console.log(`Uppy endpoint: ${process.env.REACT_APP_API_URL}upload/${projectId}/storey`);
                            uppy2.use(XHRUpload, { 
                              endpoint: `${process.env.REACT_APP_API_URL}upload/${projectId}/storey`, 
                              method: 'post' 
                            })
                            .on('complete', (result) => {
                              // console.log('Upload result:', result)
                              if (result.failed.length === 0) {
                                // console.log('Upload response.stories :', result.successful[0].response.body.stories)
                                setStories(result.successful[0].response.body.stories);
                              }
                              
                            })
                          }
                          else
                          {
                            uppy2.use(AwsS3Multipart, {
                              limit: 0, // The maximum amount of chunks to upload simultaneously. Set to 0 to disable limiting
                              retryDelays: [0, 1000, 3000, 5000], // When uploading a chunk fails, automatically try again after the millisecond intervals specified in this array. By default, we first retry instantly; if that fails, we retry after 1 second; if that fails, we retry after 3 seconds, etc. Set to null to disable automatic retries, and fail instantly if any chunk fails to upload.
                              companionUrl:
                                process.env.REACT_APP_UPPY_COMPANION_URL,
                            });
                          }

                          uppy2.on("complete", (result) => {
                            setLoading(true);

                            // console.log("UPPY on complete", result);
                            // const uploadURL = result.successful[0].uploadURL;
                            const email = JSON.parse(
                              window.localStorage.getItem("email")
                            );
                            const code = JSON.parse(
                              window.localStorage.getItem("verification_code")
                            );

                            // POST https://stage-portal.zentuvo.com/data/ImageProcessingDataService/uploadPhotos
                            // {"imageImportData":{"orderId":10558,"workOrderId":63370,"userUID":"fi_ittest","type":"Floorplan","isProcessed":false,"srcFolder":"2021_06_25_14_47_07_196","files":{"fileData":[{"name":"Floorplan.jpg","type":"DigitalStyling"}]}}}
                            try {
                              // TODO: may somebody can came up with better idea how to do this final confirmation call?
                              let json;

                              if (process.env.REACT_APP_API_URL.includes('zentuvowebshop')) 
                              {
                                let stories2 = result.successful[0].response.body.stories.map((item) => {
                                  return {
                                    Id: item.id,
                                    StoreyId: item.storeyId
                                  }
                                });
                                // console.log(result.successful[0].response.body.stories)
                                // console.error(stories2)
                                json = {
                                  PlanOrderId: projectId,
                                  ExtraInformation: "",
                                  IsExpressDelivery: false,
                                  Stories: stories2
                                };                              
                              } 
                              else 
                              {
                                json = {
                                  imageImportData: {
                                    orderId: child2.orderId,
                                    workOrderId: child2.workOrderId,
                                    userUID: email,
                                    type: child2.name,
                                    isProcessed: false,
                                    srcFolder: path,
                                    s3Upload: true,
                                    files: {
                                      fileData: [
                                        {
                                          name: "Floorplan.jpg",
                                          type: child2.name,
                                        },
                                      ],
                                    },
                                  },
                                };
                              }
                              // console.log("Passed to /uploadPhotos as body: ", json);
                              fetch(
                                process.env.REACT_APP_API_URL + "uploadPhotos",
                                {
                                  method: "POST",
                                  headers: {
                                    Accept: "application/json",
                                    "Content-Type": "application/json",
                                    Authorization:
                                      "Basic " + btoa(email + ":" + code),
                                  },
                                  body: JSON.stringify(json),
                                }
                              )
                                // .then((response) => {
                                // console.log("uploadPhotos.onSuccess", response);
                                // setLoading(false);
                                // return response.json();
                                // })
                                .catch((error) => {
                                  // setLoading(false);
                                  setError(
                                    t("failedSubmitOrder") + error //error.message
                                  );
                                  // console.error("submitOrder.Failed2 to submit order. Error: ", error);
                                })
                                .finally(() => {
                                  // console.log("uploadPhotos.finally");
                                  setLoading(false);
                                });

                            } catch (fetchError) {
                              setLoading(false);
                              setError(
                                t("failedUploadPhotos") + fetchError //fetchError.message
                              );
                              // console.error(
                              //   "submitOrder.Failed2 to submit order. Error: ",
                              //   fetchError.message
                              // );
                            }
                          });
                          return (
                            <div key={child2.id}>
                              <div>
                                "Upload floorplans (jpg/pdf) for refining them
                                in Zentuvo"
                              </div>
                              {/* ---3---id:{child2.id} dataSet:{child2.dataSet}{" "}
                              name:
                              {child2.name} orderId:{child2.orderId} type:
                              {child2.type} uploadState:{child2.uploadState}{" "}
                              workOrderId:{child2.workOrderId} */}
                              <Dashboard
                                id={"Dashboard" + child2.id}
                                uppy={uppy2}
                                trigger=".UppyModalOpenerBtn"
                                inline={true}
                                target=".DashboardContainer"
                                replaceTargetContent={true}
                                showProgressDetails={true}
                                note="JPG/PDF only, 1–30 files, up to 50 GB"
                                height={470}
                                width={360}
                                metaFields={[
                                  {
                                    id: "name",
                                    name: "Name",
                                    placeholder: "file name",
                                    render: ({ value, onChange }, h) => {
                                      let select_options = [
                                        h(
                                          "option",
                                          { value: "" },
                                          "Select option"
                                        ),
                                        h(
                                          "option",
                                          { value: "opt1" },
                                          "Option 1"
                                        ),
                                        h(
                                          "option",
                                          { value: "opt2" },
                                          "Option 2"
                                        ),
                                      ];
                                      return h(
                                        "select",
                                        {
                                          class:
                                            "uppy-c-textInput uppy-Dashboard-FileCard-input",
                                          type: "checkbox",
                                          onChange: (ev) =>
                                            onChange(ev.target.value),
                                          defaultChecked: value,
                                        },
                                        select_options
                                      );
                                    },
                                  },
                                ]}
                                browserBackButtonClose={false}
                                locale={{
                                  strings: {
                                    // Text to show on the droppable area.
                                    // `%{browse}` is replaced with a link that opens the system file selection dialog.
                                    dropHereOr: "Drop here or %{browse}",
                                    // Used as the label for the link that opens the system file selection dialog.
                                    browse: "browse",
                                  },
                                }}
                              />
                            </div>
                          );
                        })}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      ))}
    </div>
  );
};

S3Uploader.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  //   source: PropTypes.string.isRequired,
};

export default S3Uploader;
