import React, { useState } from "react";
import MuiPhoneNumber from "material-ui-phone-number";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import { Alert, AlertTitle } from '@mui/material';
import { useStickyState } from "../../../utils/useStickyState";
// import makeStyles from "@mui/styles/makeStyles";

// const useStyles = makeStyles((theme) => ({
//   form: {
//     width: "100%", // Fix IE 11 issue.
//     marginTop: theme.spacing(1),
//   },
//   submit: {
//     margin: theme.spacing(6, 0, 4),
//   },
// }));

export default function MainForm(props) {
  // const classes = useStyles();
  const { t } = useTranslation();

  const [firstName, setFirstName] = useStickyState("", "first_name");
  const [lastName, setLastName] = useStickyState("", "last_name");
  const [phone1, setPhone1] = useStickyState("", "phone1");
  const [phone2, setPhone2] = useStickyState("", "phone2");

  const [error, setError] = useState({
    title: "Error",
    text: "Some text...",

    display: false
  });

  const { classes } = props;

  const submit = () => {
    //   alert("TODO: Submit to server");
    try {
      const json = {
        first_name: firstName,
        last_name: lastName,
        phone1: phone1.replaceAll(" ", ""),
        phone2: phone2.replaceAll(" ", ""),
      };
      const email = JSON.parse(window.localStorage.getItem("email"));
      const code = JSON.parse(window.localStorage.getItem("verification_code"));
      console.log("updateContacts.email, code", email, code);
      if (!email || !code) {
        setError({
          title: "Error!",
          text: "Please make sure you provided both email and password",

          display: true
        })
        // alert("Please make sure you provided both email and password");
        // window.location = "/sign-in";
        return;
      }

      fetch(process.env.REACT_APP_API_URL + `updateTUsers`, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Basic " + btoa(email + ":" + code),
        },
        body: JSON.stringify(json),
      })
        .then((response) => {
          console.log("updateContacts.onSuccess", response);
          if (response.status === 401) {
            console.log(
              "updateContacts.onSuccess2",
              response.json(),
              response.data
            );

            setError({
              title: "Error!",
              text: "Either email or password is wrong",
    
              display: true
            })

            // alert("Either email or password is wrong");
          }
          // setLoaded(true);
          // return response.json();
        })
        .catch((reason) => {
          setError({
            title: "Error!",
            text: reason,
  
            display: true
          });
          // alert(reason);
          console.log("updateContacts.onError", reason);
        });
    } catch (fetchError) {
      // alert(fetchError.message);
      setError({
        title: "Error!",
        text: fetchError.message,

        display: true
      });
      console.error("Failed to update contacts. Error: " + fetchError.message);
    }
  };

  return (
    // <div className={classes.form}>
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <TextField
          variant="outlined"
          autoComplete="fname"
          name="firstName"
          required
          fullWidth
          id="firstName"
          label={t("First name")}
          autoFocus
          className={classes.form}
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          variant="outlined"
          required
          fullWidth
          id="lastName"
          label={t("Last name")}
          name="lastName"
          autoComplete="lname"
          className={classes.form}
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
      </Grid>

      <Grid item xs={12}>
        <MuiPhoneNumber
          className={classes.form}
          variant="outlined"
          required
          id="phone1"
          name="phone1"
          label={t("Phone (main)")}
          fullWidth
          autoComplete="phone"
          defaultCountry={"fi"}
          preferredCountries={["fi", "se", "no", "dn", "de", "fr"]}
          regions={["europe"]}
          value={phone1} // TODO
          onChange={(e) => setPhone1(e)}
        />
      </Grid>

      <Grid item xs={12}>
        <MuiPhoneNumber
          className={classes.form}
          variant="outlined"
          required
          id="phone2"
          name="phone2"
          label={t("Phone (other)")}
          fullWidth
          autoComplete="phone"
          defaultCountry={"fi"}
          preferredCountries={["fi", "se", "no", "dn", "de", "fr"]}
          regions={["europe"]}
          value={phone2} // TODO
          onChange={(e) => setPhone2(e)}
        />
      </Grid>

      <Grid item xs={12} style={{ display: error.display ? "" : "none", paddingTop: "20px", widht: "100%" }}>
        <Alert severity="error" style={{ widht: "100%" }}>
          <AlertTitle>{error.title}</AlertTitle>

          {error.text}
        </Alert>
      </Grid>

      <Grid item xs={12}>
      <Button className={classes.submit}
        // className={{
        //   marginTop: 60,
        //   marginBottom: 60,
        // }}
        // type="submit"
        fullWidth
        variant="contained"
        color="primary"
        onClick={() => submit()}
      >
        {t("Save Changes")}
      </Button>
      </Grid>
    </Grid>
    // </div>
  );
}
