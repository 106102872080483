import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { useTranslation } from "react-i18next";
import Link from "@mui/material/Link";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://my.zentuvo.com/">
        Zentuvo
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function Footer(props) {
  const { t } = useTranslation();
  const footers = [
    {
      title: t("Company"),
      description: [
        { title: t("Contact us"), link: t("link-contact-us") },
        { title: t("Staff"), link: t("link-staff") },
        { title: t("Join the team"), link: t("link-join-the-team") },
      ],
    },
    {
      title: t("Services"),
      description: [
        { title: t("Photo"), link: t("link-photo") },
        { title: t("Video"), link: t("link-video") },
        { title: t("Digital"), link: t("link-digital") },
        { title: t("Visualisation"), link: t("link-visualisation") },
      ],
    },
    {
      title: t("Resources"),
      description: [{ title: t("Preparations"), link: t("link-preparations") }],
    },
    {
      title: t("Legal"),
      description: [{ title: t("Terms of use"), link: t("link-terms-of-use") }],
    },
  ];

  return (
    <Container component="footer" style={{ width: "100%", margin: "auto" }}>
      {/* <hr /> */}

      <Grid container spacing={4} justifyContent="space-evenly">
        {footers.map((footer) => (
          <Grid item xs={6} sm={3} key={footer.title}>
            <Typography variant="h6" color="textPrimary" gutterBottom>
              {footer.title}
            </Typography>
            <ul>
              {footer.description.map((item,idx2) => (
                <li key={idx2}>
                  <Link
                    target="_blank"
                    // href={`/${item.toLowerCase().split(" ").join("-")}`}
                    href={
                      item.link.startsWith("http") ? item.link : `/${item.link}`
                    }
                    variant="subtitle1"
                    color="textSecondary"
                  >
                    {item.title}
                  </Link>
                </li>
              ))}
            </ul>
          </Grid>
        ))}
      </Grid>

      <Box mt={5}>
        <Copyright />
      </Box>

      <br />
    </Container>
  );
}
