import React, { useState, useEffect } from "react";
import  { Redirect } from 'react-router-dom'
import Gallery from "../gallery/gallery";
import MUIDataTable from "mui-datatables";

import Loading from "../misc/Loading";
import Error from "../misc/Error";
import { useTranslation } from "react-i18next";

import Button from "@mui/material/Button";

function MyOrders() {
  const { t } = useTranslation();
  // const classes = useStyles();
  const [isLoaded, setLoaded] = useState(false);
  const [error, setError] = useState(null);
  const [showGallery, setShowGallery] = useState(false);
  const [items, setItems] = useState([]);
  const [itemsId, setItemsId] = useState([]);

  const statusReady = t("Ready");

  // componentDidMount() {
  useEffect(() => {
    setError(null);
    setLoaded(false);

    // this.setState({ error: null });
    // this.setState({ isLoaded: false });
    try {
      const email = JSON.parse(window.localStorage.getItem("email"));
      const code = JSON.parse(window.localStorage.getItem("verification_code"));
      // console.log("MyOrders.email, code", email, code);
      if (!email || !code) {
        console.log("MyOrders2.email, code", email, code);
        setItems([]);
        window.location = "/sign-in";
        // this.setState({ items: [] });
        return;
      }
      // console.log("MyOrders3.email, code", email, code);

      fetch(process.env.REACT_APP_API_URL + "myOrders", {
        method: "get",
        headers: {
          "Content-Type": "text/plain",
          Authorization: "Basic " + btoa(email + ":" + code),
        },
      })
        .then((response) => {
          // console.log(
          //   "products.onSuccess",
          //   response,
          // );
          setLoaded(true);
          // this.setState({ isLoaded: true });
          return response.json();
        })
        .then((data) => {
          // console.log("myOrders.onSuccess2", data);
          if (!Array.isArray(data)) {
            setError({
              message: t("Error (8756): Sorry. No connecton to server."),
            });
            return;
          }
          /* convert fetched data to necessary format */
          const items = data.map((element) => {
            // console.log("status", element, element.distributed);
            //const createdAt = Date.parse(element.created_at);
            const createdAt = new Date(element.created_at);
            /* get time difference between order creation and now (in hours)). If difference more than 1 hour->set status "In Progress" */
            const diff = new Date() - createdAt;
            const hh = Math.floor(diff / 1000 / 60 / 60);
            // TODO: uncomment this:
            const status = element.distributed
              ? t("Ready")
              : hh > 1
              ? t("In Progress")
                : t("Open");
            //const status = t("Ready");
            // console.log("status", status, element.status);
            return {
              id: element.id,
              created_at:
                createdAt.toLocaleDateString() +
                " " +
                createdAt.toLocaleTimeString(),
              address: element.address,
              status: status,
              distributed: element.distributed,
            };
          });
          // data((item) => {
          //   let row = [];

          //   row.push(item.orderId);
          //   row.push(item.created_at);
          //   row.push(item.address);
          //   row.push(item.status);

          //   data.push(row);
          // });
          setItems(items);
          // this.setState({ items });
        });
    } catch (fetchError) {
      setError({
        message:
          t("Sorry. Failed to fetech your orders. Error: ") +
          fetchError.message,
      });
      // this.setState({
      //   error: {
      //     message:
      //       t("Sorry. Failed to fetech your orders. Error: ") +
      //       fetchError.message,
      //   },
      // });
    }
  }, []);

  const openGallery = (rowData) => {
    console.log(rowData);

    setItemsId(rowData[0]);
    //const statusReady = t("Ready");
    // if (rowData[3] === statusReady) {
    if (rowData[5]/*distributed*/) {
      setShowGallery(true);
      window.location = `/view?id=${rowData[0]}`;
      // let images = this.state.items.filter(
      //   (item) => item.orderId === rowData[0]
      // )[0].images;
      // setImages(images);
      // this.setState({ images });
    } else {
      setShowGallery(false);
      // alert(t("Images are not ready yet"));
      window.location = `/uploader?id=${rowData[0]}`;
    }
  };

  // render() {
  // const { t } = this.props;
  // if (!this.state.isLoaded) return <Loading />;
  if (!isLoaded) return <Loading />;
  // if (this.state.error) return <Error error={this.state.error} />;
  if (error) return <Error error={error} />;

  if (!items)
    return <div>{t('No Orders. You can place one on "New Order" page.')}</div>;
  if (showGallery) window.location = `/order?orderId=${itemsId}`;

  const columns = [
    {
      name: "id",
      label: t("id"),
      options: {
        display: false,
        filter: true,
        sort: true,
      },
    },
    {
      name: "created_at",
      label: t("Date"),
      options: {
        filter: true,
        sort: true,
        // customBodyRender: (value, tableMeta, updateValue) => {
        //   /* get time(hours) difference between order creation and now. If difference more than hour->set status "In Progress" */
        //   const diff = new Date() - Date.parse(tableMeta.rowData[1]);
        //   const hh = Math.floor(diff / 1000 / 60 / 60);
        //   console.log(
        //     "customBodyRender",
        //     value,
        //     tableMeta.rowData[1],
        //     hh,
        //     updateValue
        //   );
        //   return (
        //     <div>{value ? "Ready" : hh > 1 ? "In Progress" : "Open"}</div>
        //   );
        // },
      },
    },
    {
      name: "address",
      label: t("Address"),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "status",
      label: t("Status"),
      options: {
        filter: true,
        sort: true,
        // customBodyRender: (value, tableMeta, updateValue) => {
        //   /* get time(hours) difference between order creation and now. If difference more than hour->set status "In Progress" */
        //   const diff = new Date() - Date.parse(tableMeta.rowData[1]);
        //   const hh = Math.floor(diff / 1000 / 60 / 60);
        //   console.log(
        //     "customBodyRender",
        //     value,
        //     tableMeta.rowData[1],
        //     hh,
        //     updateValue
        //   );
        //   return (
        //     <div>{value ? "Ready" : hh > 1 ? "In Progress" : "Open"}</div>
        //   );
        // },
      },
    },
    {
      name: "Upload",
      label: t("Upload"),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          // console.log(
          //   "value",  
          //   value,
          //   "tableMeta",
          //   tableMeta,
          //   "tableMeta.rowData",
          //   tableMeta.rowData
          // );

          return (
            <div>
              {/* <span>{tableMeta.rowData[5]?"yes":"no"}</span> */}
              <Button
                variant="contained"
                color="primary"
                size="small"
                //disabled={tableMeta.rowData[3] !== statusReady}
                disabled={!tableMeta.rowData[5]}
                href={`/view?id=${tableMeta.rowData[0]}`}
              >
                {t("Images")}
              </Button>
              <Button
                variant="contained"
                color="primary"
                size="small"
                // disabled={tableMeta.rowData[3] === statusReady}
                disabled={tableMeta.rowData[5]}
                href={`/uploader?id=${tableMeta.rowData[0]}`}
              >
                {t("Upload")}
              </Button>
            </div>
          );
        },
      },
    },
    {
      name: "distributed",
      label: "original status",
      options: {
        filter:false,
        sort:false,
        display:false,
        viewColumns:false
      },
    },
  ];

  const options = {
    filter: true,
    filterType: "dropdown",
    responsive: "vertical",
    print: false,
    download: false,
    pagination: false,
    selectableRows: "none",
    search: items.length > 5,
    onRowClick: (rowData) => openGallery(rowData),
  };

  return (
    <div>
      <div
        style={{
          width: window.screen.width > 1000 ? "40%" : "90%",
          margin: "auto",
          paddingTop: "50px",
        }}
      >
        <MUIDataTable
          title={t("My orders")}
          data={items}
          columns={columns}
          options={options}
        />
      </div>
    </div>
  );
}

export default MyOrders;
