import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { Alert, AlertTitle } from '@mui/material';
import { useTranslation } from "react-i18next";
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  // paper: {
  //   marginTop: theme.spacing(8),
  //   display: 'flex',
  //   flexDirection: 'column',
  //   alignItems: 'center',
  // },
  // avatar: {
  //   margin: theme.spacing(1),
  //   backgroundColor: theme.palette.secondary.main,
  // },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function PasswordForm(props) {
  const classes = useStyles();
  // const { classes } = props;

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const [error, setError] = useState({
    title: "Error",
    text: "Error happened...",

    display: false
  });

  const { t } = useTranslation();

  const oldPasswordChange = (event) => setOldPassword(event.target.value);
  const newPasswordChange = (event) => setNewPassword(event.target.value);
  const confirmNewPasswordChange = (event) => setConfirmNewPassword(event.target.value);

  const submit = (e) => {
    alert("Function doesn't work at the moment " + e);
  }


  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          className={classes.submit}
          variant="outlined"
          autoComplete="password"
          name="password"
          fullWidth
          id="password"
          label={t("Old Password")}
          autoFocus
          value={oldPassword}
          onChange={oldPasswordChange}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          className={classes.submit}
          variant="outlined"
          fullWidth
          id="newPassword"
          label={t("New Password")}
          name="newPassword"
          value={newPassword}
          onChange={newPasswordChange}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          className={classes.submit}
          variant="outlined"
          fullWidth
          id="newPassword"
          label={t("Confirm New Password")}
          name="newPassword"
          value={confirmNewPassword}
          onChange={confirmNewPasswordChange}
        />
      </Grid>

      <Grid item xs={12} style={{ display: error.display ? "" : "none", paddingTop: "20px", widht: "100%" }}>
        <Alert severity="error" style={{ widht: "100%" }}>
          <AlertTitle>{error.title}</AlertTitle>

          {error.text}
        </Alert>
      </Grid>

      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        className={classes.submit}
        onClick={e => submit(e)}
      >
        {t("Submit")}
      </Button>

      <Button
        className={classes.submit}
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        onClick={props.goBack}
      >
        {t("Back")}
      </Button>
    </Grid>
  );
}
