import React from "react";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import CardMedia from "@mui/material/CardMedia";
import IconButton from "@mui/material/IconButton";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import GetAppIcon from "@mui/icons-material/GetApp";

export default function PhotoCard(props) {
  const { item } = props;

  if (!item) return <div>Item not found</div>;

  const iconSize = `${20}px`;
  return (
    <Card
      style={{ width: "225px", height: "188px", backgroundColor: "#313131" }}
      elevation={5}
    >
      <CardActionArea
        style={{ width: "225px", height: "150px", backgroundColor: "black" }}
      >
        <CardMedia
          component="img"
          alt={item.name}
          width="100%"
          // image={`${process.env.REACT_APP_ZIMG_URL}ThumbsDataService/view/${props.projectId}/PORTAL_SMALL/${item.id}`}
          image={`${process.env.REACT_APP_API_URL}thumb/${props.projectId}/${item.id}/ImgSS_225x150`}
          title={item.name}
          onClick={() => props.open(item)}
        />
      </CardActionArea>
      <CardActions>
        <IconButton
          aria-label="add"
          style={{ width: iconSize, height: iconSize }}
          onClick={() => props.change(item)}
          size="large">
          {item.selected ? (
            <HighlightOffIcon style={{ color: "red", size: iconSize }} />
          ) : (
            <AddCircleOutlineIcon style={{ color: "green", size: iconSize }} />
          )}
        </IconButton>
        <IconButton
          aria-label="download"
          style={{ width: iconSize, height: iconSize }}
          onClick={() => props.download(item)}
          size="large">
          <GetAppIcon style={{ color: "white", size: iconSize }} />
        </IconButton>

        <span style={{ color: "white" }}>{item.caption}</span>
      </CardActions>
    </Card>
  );
}
