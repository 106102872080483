import React, { Component } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import withStyles from '@mui/styles/withStyles';
import Container from "@mui/material/Container";
import { Alert, AlertTitle } from '@mui/material';
import ForgotPassword from "./components/ForgotPassword";

const useStyles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    background: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

class SignIn extends Component {
  state = {
    email: "",
    password: "",

    error: {
      title: "",
      text: "",

      display: false
    },

    forgotPassword: false
  };

  handleEmailChange = (event) => this.setState({ email: event.target.value });
  handlePasswordChange = (event) =>
    this.setState({ password: event.target.value });

  submit = () => {
    try {
      const json = {
        email: this.state.email,
        code: this.state.password,
      };
      fetch(process.env.REACT_APP_API_URL + "verifyEmailAndCode", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(json),
      })
        .then((response) => {
          if (response.status === 401) {

            const error = {
              title: "Can`t sign-in",
              text: "Combination of email and password is wrong",
        
              display: true
            }

            this.setState({ error });
          } else if (response.status === 200) {
            window.localStorage.setItem(
              "email",
              JSON.stringify(this.state.email)
            );
            window.localStorage.setItem(
              "verification_code",
              JSON.stringify(this.state.password)
            );

            window.location = "/my-orders";
          }
          // return response.json();
        })
        .catch((reason) => {
          const error = {
            title: "Error",
            text: `${reason}`,
      
            display: true
          }
          this.setState({ error });
        });
    } catch (fetchError) {
      const error = {
        title: "Error",
        text: `Error: ${fetchError.message}`,
  
        display: true
      }

      this.setState({ error });
    }
  };

  render() {
    const { classes } = this.props;

    if (this.state.forgotPassword) return <ForgotPassword goBack={() => this.setState({ forgotPassword: false })} />

    return (
      <div>
        <Container component="main" maxWidth="xs">
          <CssBaseline />

          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>

            <Typography component="h1" variant="h5">
              Sign in
            </Typography>

            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={this.handleEmailChange}
            />

            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="password"
              onChange={this.handlePasswordChange}
            />

            <Alert severity="error" style={{ display: this.state.error.display ? "" : "none" }}>
              <AlertTitle>{this.state.error.title}</AlertTitle>

              {this.state.error.text}
            </Alert>

            <Button
              // type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={this.submit}
            > Sign In </Button>

            <Link style={{ cursor: "pointer" }} onClick={() => this.setState({ forgotPassword: true })}>Forgot my password</Link>
          </div>
        </Container>
      </div>
    );
  }
}

export default withStyles(useStyles)(SignIn);
