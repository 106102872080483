import * as React from "react";
import { cloneElement } from "react";
import { createPortal } from "react-dom";
import PropTypes from "prop-types";
// import { useTranslate, Record, warning } from 'ra-core';
import { useTranslation } from "react-i18next";

const Title = ({ className, defaultTitle, record, title, ...rest }) => {
  const { t } = useTranslation();
  //   const translate = useTranslate();
  const container =
    typeof document !== "undefined"
      ? document.getElementById("react-admin-title")
      : null;
  if (!container) return null;
  console.warn(
    !defaultTitle && !title,
    "Missing title prop in <Title> element"
  );
  //   warning(!defaultTitle && !title, "Missing title prop in <Title> element");

  const titleElement = !title ? (
    <span className={className} {...rest}>
      {defaultTitle}
    </span>
  ) : typeof title === "string" ? (
    <span className={className} {...rest}>
      {t(title, { _: title })}
    </span>
  ) : (
    cloneElement(title, { className, record, ...rest })
  );
  return createPortal(titleElement, container);
};

export const TitlePropType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.element,
]);

Title.propTypes = {
  defaultTitle: PropTypes.string,
  className: PropTypes.string,
  record: PropTypes.any,
  title: TitlePropType,
};

export default Title;
