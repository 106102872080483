import React from 'react';
import { useTranslation } from "react-i18next";
import Uppy from '@uppy/core';
import { Dashboard } from "@uppy/react";
import AwsS3Multipart from '@uppy/aws-s3-multipart';
import fi_FI from '@uppy/locales/lib/fi_FI';
import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';

const uppy = new Uppy({
  debug: true,
  restrictions: {
    maxFileSize: 50000000,
    maxNumberOfFiles: 30,
    minNumberOfFiles: 1,
    allowedFileTypes: [".pdf", ".jpg", ".jpeg", ".tiff", ".png"],
    // allowedFileTypes: ["image/*", "video/*"],
  },
  autoProceed: true
})
.use(AwsS3Multipart, {
  limit: 0, // The maximum amount of chunks to upload simultaneously. Set to 0 to disable limiting
  retryDelays: [0, 1000, 3000, 5000], // When uploading a chunk fails, automatically try again after the millisecond intervals specified in this array. By default, we first retry instantly; if that fails, we retry after 1 second; if that fails, we retry after 3 seconds, etc. Set to null to disable automatic retries, and fail instantly if any chunk fails to upload.
  companionUrl: process.env.REACT_APP_UPPY_COMPANION_URL,
});

const S3UploadBeforeOrder = ({ path, onUploadComplete }) => {
  const { t } = useTranslation();
  const [files, setFiles] = React.useState([]);

  uppy.setMeta({
    path,
  });

  uppy.on('file-added', (file) => {
    setFiles([...files, file]);
  });

  uppy.on('upload', (data) => {
    // data object consists of `id` with upload ID and `fileIDs` array
    // with file IDs in current upload
    // data: { id, fileIDs }
    console.log(`Starting upload ${data.id} for files ${data.fileIDs}`);
  });

  uppy.on('complete', (result) => {
    console.log('Upload complete! We’ve uploaded these files:', result.successful, ' to: ', path);
    console.log('result:', result);
    
    onUploadComplete(result.successful); // Invoke the callback function passed as prop
  });

  return (
    <center>
      <Dashboard
        id={"DashboardUppyFloorplanImagesUplo"}
        uppy={uppy}
        inline={true}
        replaceTargetContent={true}
        showProgressDetails={true}
        proudlyDisplayPoweredByUppy={false}
        browserBackButtonClose={false}
        note={t("imagesRequirements")}
        height={405}
        width={450}
        locale={fi_FI}
        // locale={{
        //   strings: {
        //     // Text to show on the droppable area.
        //     // `%{browse}` is replaced with a link that opens the system file selection dialog.
        //     dropPasteFiles: t("uploadImagesText"),
        //     // Used as the label for the link that opens the system file selection dialog.
        //     browse: t("browse"),
        //   },
        // }}
      />
      <ul>
        {files.map((file) => (
          <li key={file.id}>
            {file.name}
          </li>
        ))}
      </ul>      
      </center> 
  );
};

export default S3UploadBeforeOrder;