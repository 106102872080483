import React, { Component } from "react";

import Button from "@mui/material/Button";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Fab from "@mui/material/Fab";
import FlipMove from "react-flip-move";

import Card from "./components/card";
import Dialog from "./components/dialog";
import Alert from "../misc/alert";
import FullSizeGallery from "./components/fullSizeGallery";

import JSZip from "jszip";
import { saveAs } from "file-saver";

import { withTranslation } from "react-i18next";

class Gallery extends Component {
  state = {
    dataLoaded: false,

    items: [],
    selected: [],
    extra: [],

    selectedItems: [],

    dialogOpen: false,
    alert: false, // null = hide, text = show

    showScrollUp:
      document.body.clientHeight > document.documentElement.clientHeight,

    windowWidth: window.screen.width,

    // gallery
    openGallery: window.location.search.split("=").length > 2,
    item: null,
    itemId: null,
    projectId: window.location.search.split("=")[1].split("&")[0],
  };

  handleResize = (e) => this.setState({ windowWidth: window.innerWidth });

  scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  componentDidMount() {
    console.log("gallery.componentDidMount.projectId", this.state.projectId);
    const email = JSON.parse(window.localStorage.getItem("email"));
    const code = JSON.parse(window.localStorage.getItem("verification_code"));
    if (!email || !code) return (window.location = "/sign-in");

    if (email && code) {
      fetch(
        process.env.REACT_APP_API_URL + `myImages/${this.state.projectId}`,
        {
          method: "get",
          headers: {
            "Content-Type": "text/plain",
            Authorization: "Basic " + btoa(email + ":" + code),
          },
        }
      )
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          if (data.length === 0) {
            return;
          }
          let items = data;
          let item = items[0];
          let itemId = item.id;
          let selected = items.filter((item) => item.selected);
          let extra = items.filter((item) => item.extra_image);

          this.setState({
            dataLoaded: true,
            items,
            selected,
            extra,
            item,
            selectedItems: selected.length,
            itemId,
          });
        });

      //   setItems([]);
      // this.setState({ items: [] });
    } else {
      alert("You should be sign in to see images");
    }

    this.setState({
      showScrollUp:
        document.body.clientHeight > document.documentElement.clientHeight,
    });
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    this.setState({
      showScrollUp:
        document.body.clientHeight > document.documentElement.clientHeight,
    });
    window.addEventListener("resize", this.handleResize);
  }

  change = (item) => {
    let { items, selected, extra } = this.state;

    items = items.map((i) => {
      if (i.id === item.id) {
        i.selected = !i.selected;
        i.extra = !i.extra;
      }

      return i;
    });

    if (item.selected) {
      selected.push(item);
      extra = extra.filter((i) => i.id !== item.id);
    } else {
      extra.push(item);
      selected = selected.filter((i) => i.id !== item.id);
    }

    this.setState({ items, selected, extra });
    this.setState({
      showScrollUp:
        document.body.clientHeight > document.documentElement.clientHeight,
    });
  };

  openGallery = (item) => {
    window.location += `&itemId=${item.id}`;

    this.setState({ item, itemId: item.id, openGallery: true });
  };

  downloadImage = (item) => {
    // window.location += `&itemId=${item.id}`;
    console.log("downloadImage", item);

    // var zip = new JSZip();
    // zip.file("Hello.txt", "Hello World\n");
    // var img = zip.folder("images");
    // img.file("smile.gif", imgData, { base64: true });
    // zip.generateAsync({ type: "blob" }).then(function (content) {
    //   // see FileSaver.js
    //   saveAs(content, "example.zip");
    // });

    fetch(
      // `${process.env.REACT_APP_ZIMG_URL}ThumbsDataService/view/${this.state.projectId}/PORTAL_LARGE_WATERMARK/${item.id}`,
      `${process.env.REACT_APP_API_URL}file/${this.state.projectId}/${item.id}`,
      {
        method: "GET",
        // mode: "no-cors",
        cache: "no-store",
      }
    )
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", item.name); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };
  downloadImages = async (item) => {
    // console.log("downloadImages", item, this.state.items);

    /* Create a new instance of JSZip and a folder named 'collection' where*/
    /* we will be adding all of our files*/
    let zip = new JSZip();
    let folder = zip.folder("collection");

    /* Add the image to the folder */
    for (let index = 0; index < this.state.items.length; index++) {
      const fileInfo = this.state.items[index];

      const imageUrl = `${process.env.REACT_APP_API_URL}file/${this.state.projectId}/${fileInfo.id}`;

      // Fetch the image and parse the response stream as a blob
      const imageBlob = await fetch(imageUrl, {
        method: "GET",
        // mode: "no-cors",
        cache: "no-store",
      }).then((response) => response.blob());

      // create a new file from the blob object
      const imageFile = new File([imageBlob], fileInfo.name);

      // console.log("File: ", fileInfo.name, imageUrl);
      folder.file(fileInfo.name, imageFile);
    }

    /* Generate a zip file asynchronously and trigger the download */
    folder
      .generateAsync({ type: "blob" })
      .then((content) => saveAs(content, `${this.state.projectId}`));
  };

  paginateGallery = (paginate, currItem) => {
    if (currItem.selected) {
      let idx = this.state.selected.indexOf(currItem) + paginate;
      if (idx >= this.state.selected.length) idx = 0;
      else if (idx < 0) idx = this.state.selected.length - 1;

      this.setState({ item: this.state.selected[idx] });
    } else if (currItem.extraImage) {
      let idx = this.state.extra.indexOf(currItem) + paginate;
      if (idx >= this.state.extra.length) idx = 0;
      else if (idx < 0) idx = this.state.extra.length - 1;

      console.log(this.state.extra[idx], idx);
      this.setState({ item: this.state.extra[idx] });
    }
  };

  dialogs = () => {};

  confirm = () => {
    if (this.state.selected.length < this.state.selectedItems) {
      let alert = `You have ${
        this.state.selectedItems - this.state.selected.length
      } free extra images`;
      this.setState({ alert });
    } else if (this.state.selected.length === this.state.selectedItems) {
      window.location = "/#"; // redirect
    } else if (this.state.selected.length > this.state.selectedItems) {
      this.setState({ dialogOpen: true });
    }
  };

  animation = (element, node) => {
    // Wait half the duration of the FlipMove animation, and then paint it!
    setTimeout(() => node.classList.add("painted"), 500 / 6);
  };

  render() {
    const { t } = this.props;

    console.log(window.location);
    if (!this.state.dataLoaded) return <div></div>;

    let ItemsInRow = Math.floor((this.state.windowWidth - 25) / 235);
    let rows = Math.ceil(this.state.selected.length / ItemsInRow);

    let selected = [];
    for (let rowI = 0; rowI < rows; rowI++) {
      let row = [];

      for (let colI = 0; colI < ItemsInRow; colI++) {
        let idx = ItemsInRow * rowI + colI;
        let item = this.state.selected[idx];

        if (item) row.push(item);
      }

      selected.push(row);
    }

    ItemsInRow = Math.floor((this.state.windowWidth - 25) / 235);
    rows = Math.ceil(this.state.extra.length / ItemsInRow);

    let extra = [];
    for (let rowI = 0; rowI < rows; rowI++) {
      let row = [];

      for (let colI = 0; colI < ItemsInRow; colI++) {
        let idx = ItemsInRow * rowI + colI;
        let item = this.state.extra[idx];

        if (item) row.push(item);
      }

      extra.push(row);
    }

    let cards = [...selected, "divider", ...extra];

    const fabStyle = {
      margin: 0,
      top: "auto",
      right: 20,
      bottom: 20,
      left: "auto",
      position: "fixed",
      display: this.state.showScrollUp ? "" : "none",
    };

    return (
      <div style={{ padding: "10px" }}>
        <div>
          <FullSizeGallery
            show={this.state.openGallery}
            item={this.state.item}
            close={() => this.setState({ openGallery: false })}
            paginate={this.paginateGallery}
            projectId={this.state.projectId}
          />

          <Dialog
            show={this.state.dialogOpen}
            close={() => this.setState({ dialogOpen: false })}
            title={"You chosed some extra images, redirect?"}
            text={null}
            agree={() => (window.location = "/#")}
            disagree={() => this.setState({ dialogOpen: false })}
          />

          <Alert
            show={this.state.alert}
            title={this.state.alert}
            close={() => this.setState({ alert: false })}
          />

          <Fab style={fabStyle} color={"primary"} onClick={this.scrollToTop}>
            <KeyboardArrowUpIcon />
          </Fab>
        </div>

        <div style={{ padding: "15px" }}>
          <div style={{ display: "flex", paddingLeft: "5px" }}>
            {/* <div style={{ paddingTop: "15px" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={this.confirm}
              >
                {t("Confirm")}
              </Button>
            </div> */}
            <div style={{ paddingTop: "15px" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={this.downloadImages}
              >
                {t("Download ZIP with all images")}
              </Button>
            </div>

            <div style={{ paddingLeft: "10px" }}>
              <h3 style={{ color: "black" }}>
                {t("Selected")} ({this.state.selected.length}/
                {this.state.selectedItems}):
              </h3>
            </div>
          </div>

          <FlipMove>
            {cards.map((row, idx) => {
              if (row === "divider") {
                if (idx === cards.length - 1) return <div key={idx}></div>;
                else
                  return (
                    <div key={idx}>
                      <hr />
                      <h3 style={{ color: "black", paddingLeft: "5px" }}>
                        {t("Extra")} ({this.state.extra.length}):
                      </h3>
                    </div>
                  );
              }

              return (
                <FlipMove
                  style={{ paddingBottom: "10px", display: "flex" }}
                  key={idx}
                  easing="cubic-bezier(.12,.36,.14,1.2)"
                  duration={750}
                >
                  {row.map((item) => {
                    return (
                      <div
                        style={{ paddingLeft: "5px", paddingRight: "5px" }}
                        key={item.id}
                      >
                        <Card
                          item={item}
                          change={this.change}
                          open={this.openGallery}
                          download={this.downloadImage}
                          projectId={this.state.projectId}
                        />
                      </div>
                    );
                  })}
                </FlipMove>
              );
            })}
          </FlipMove>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Gallery);
