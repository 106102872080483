import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import List from "@mui/material/List";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Fab from "@mui/material/Fab";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
    backgroundColor: "#313131",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog(props) {
  const classes = useStyles();

  const params = window.location.href.split("&");
  const orderId = params[0].split("=");
  const imgId = params[1] ? params[1].split("=") : null;

  return (
    <div>
      <Dialog
        fullScreen
        open={window.location.search.split("=").length > 2}
        onClose={() => (window.location = window.location.href.split("&")[0])}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              {props.item.name}
            </Typography>
            <Button
              color="inherit"
              onClick={() =>
                (window.location = window.location.href.split("&")[0])
              }
            >
              <CloseIcon />
            </Button>
          </Toolbar>
        </AppBar>
        <List
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingTop: "auto",
            height: "100%",
            position: "relative",
            backgroundColor: "#515151",
          }}
        >
          <div>
            <Fab
              size="medium"
              color="primary"
              aria-label="add"
              style={{ left: "25px", top: "50%" }}
              onClick={() => props.paginate(-1, props.item)}
            >
              <ChevronLeftIcon />
            </Fab>
          </div>
          <div
            style={{
              top: "50%",
              left: "50%",
              position: "absolute",
              transform: "translate(-50%, -50%)",
            }}
          >
            <img
              //src={`${process.env.REACT_APP_ZIMG_URL}ThumbsDataService/view/${props.projectId}/PORTAL_LARGE_WATERMARK/${props.item.id}`}
              src={`${process.env.REACT_APP_API_URL}file/${props.projectId}/${props.item.id}`}
              alt={props.item.name}
              width="100%"
            />
          </div>
          <div>
            <Fab
              size="medium"
              color="primary"
              aria-label="add"
              style={{ right: "25px", top: "50%" }}
              onClick={() => props.paginate(1, props.item)}
            >
              {" "}
              <ChevronRightIcon />{" "}
            </Fab>
          </div>
        </List>
      </Dialog>
    </div>
  );
}
