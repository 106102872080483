import * as React from "react";
import { Fragment } from "react";
import PropTypes from "prop-types";
// import classnames from "classnames";
import Button from "@mui/material/Button";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import makeStyles from '@mui/styles/makeStyles';
import ErrorIcon from "@mui/icons-material/Report";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import History from "@mui/icons-material/History";
// import { useTranslate } from 'ra-core';
import { useTranslation } from "react-i18next";

import Title, { TitlePropType } from "../header/Title";
// import { ClassesOverride } from "../types";

const useStyles = makeStyles(
  (theme) => ({
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      [theme.breakpoints.down('md')]: {
        padding: "1em",
      },
      fontFamily: "Roboto, sans-serif",
      opacity: 0.5,
    },
    title: {
      display: "flex",
      alignItems: "center",
    },
    icon: {
      width: "2em",
      height: "2em",
      marginRight: "0.5em",
    },
    panel: {
      marginTop: "1em",
    },
    panelDetails: {
      whiteSpace: "pre-wrap",
    },
    toolbar: {
      marginTop: "2em",
    },
  }),
  { name: "RaError" }
);

function goBack() {
  window.history.go(-1);
}

const Error = (props) => {
  const { t } = useTranslation();
  console.trace();
  console.log("Eroror.props", props);
  const {
    error,
    errorInfo,
    // classes: classesOverride,
    // classes,
    className,
    title,
    ...rest
  } = props;

  const classes = useStyles(props);
  // const translate = useTranslate();
  console.log(error);
  return (
    <Fragment>
      {error && error.title && <Title defaultTitle={error.title} />}
      {/* <div className={classnames(classes.container, className)} {...rest}> */}
      <div className={classes.container} {...rest}>
        <h1 className={classes.title} role="alert">
          <ErrorIcon className={classes.icon} />
          {t("ra.page.error")}
        </h1>
        <div>{t("ra.message.error")}</div>
        {process.env.NODE_ENV !== "production" && (
          <Accordion className={classes.panel}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              {t("ra.message.details")}
            </AccordionSummary>
            <AccordionDetails className={classes.panelDetails}>
              <div>
                {/* <h2>{translate(error.toString())}</h2> */}
                <h2>{error && error.message}</h2>
                {error && error.details}
              </div>
            </AccordionDetails>
          </Accordion>
        )}
        <div className={classes.toolbar}>
          <Button variant="contained" startIcon={<History />} onClick={goBack}>
            {t("ra.action.back")}
          </Button>
        </div>
      </div>
    </Fragment>
  );
};

Error.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  errorInfo: PropTypes.object,
  title: TitlePropType,
};

// export interface ErrorProps extends HtmlHTMLAttributes<HTMLDivElement> {
//   classes?: ClassesOverride<typeof useStyles>;
//   className?: string;
//   error: any;
//   errorInfo?: ErrorInfo;
//   title?: string;
// }
export default Error;
