import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import { useState } from "react";

import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
// import Button from "@mui/material/Button";
// import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

import PersonIcon from "@mui/icons-material/Person";
import { useTranslation } from "react-i18next";

import MainForm from "./forms/mainForm";
import PasswordForm from "./forms/PasswordForm";
import EmailForm from "./forms/emailForm";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function Profile() {
  const [displayForm, setDisplayForm] = useState("main"); // main/password/email

  const classes = useStyles();
  const { t } = useTranslation();

  let form;
  if (displayForm === "main")
    form = <MainForm classes={classes} goBack={() => setDisplayForm("main")} />;
  else if (displayForm === "password")
    form = (
      <PasswordForm classes={classes} goBack={() => setDisplayForm("main")} />
    );
  else if (displayForm === "email")
    form = (
      <EmailForm classes={classes} goBack={() => setDisplayForm("main")} />
    );
  else alert("404!");

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />

      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <PersonIcon />
        </Avatar>

        <Typography component="h1" variant="h5">
          {t("Edit Profile")}
        </Typography>

        <div className={classes.form} noValidate>
          <div className={classes.form} > {form} </div>

          {/* <Grid
            container
            spacing={2}
            style={{
              paddingTop: "25px",
              display: displayForm === "main" ? "" : "none",
            }}
          >
            <Grid item xs={12} sm={6}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                onClick={() => setDisplayForm("email")}
              >
                {t("Change E-Mail")}
              </Button>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                onClick={() => setDisplayForm("password")}
              >
                {t("Change Password")}
              </Button>
            </Grid>
          </Grid> */}
        </div>
      </div>
    </Container>
  );
}
