import React, { useState } from "react";
import makeStyles from '@mui/styles/makeStyles';
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import { Alert, AlertTitle } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

// TODO: move it to utils.js
const reEmailUnicode = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i; // eslint-disable-line

export default function EmailForm(props) {
  const [activeStep, setActiveStep] = useState(0);

  const [error, setError] = useState({
    title: "Error",
    text: "Some text...",

    display: false
  });

  const [email, setEmail] = useState("");
  const [validationCode, setValidationCode] = useState("");

  const validationChange = (event) => setValidationCode(event.target.value);

  const handleEmailFieldChange = (email) => {
    setEmailWrong(!reEmailUnicode.test(email));
    setEmail(email);
  };
  const [isEmailWrong, setEmailWrong] = useState(false);

  const classes = useStyles();
  const { t } = useTranslation();

  const handleNext = () => {
    switch (activeStep) {
      case 0 /* email */:
        // console.log("email", email);
        if (!email) {
          setError({
            title: "Error",
            text: t("Please enter your E-mail address to receive password."),

            display: true
          })
          // alert(t("Please enter your E-mail address to receive password."));
          setActiveStep(0);
          return;
        } else if (isEmailWrong) {
          // alert(t("EmailIsWrong"));
          setError({
            title: "Error",
            text: t("Email is wrong"),

            display: true
          })
          setActiveStep(0);
          return;
        } else {
          //   sendVerificationCode();
        }
        break;
      //   case 1 /* password */:
      default:
      //     console.log("handleNext.Step", activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <div className={classes.root}>
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        style={{ width: "100%" }}
      >
        <Step>
          <StepLabel>{t("New Email")}</StepLabel>
        </Step>

        <Step>
          <StepLabel>{t("Confirm Email")}</StepLabel>
        </Step>
      </Stepper>

      {activeStep === 2 ? (
        <div>
          <Typography className={classes.instructions}>
            {t("Email successfully changed")}
          </Typography>

          <br />

          <Button
            onClick={props.goBack}
            variant="contained"
            color="primary"
            fullWidth
          >
            {t("Back")}
          </Button>
        </div>
      ) : (
        <div>
          <div>
            {activeStep === 0 ? (
              <Grid>
                <TextField
                  variant="outlined"
                  autoComplete="email"
                  name="email"
                  error={isEmailWrong}
                  required
                  fullWidth
                  id="email"
                  label={t("E-mail")}
                  autoFocus
                  value={email}
                  onChange={(e) => handleEmailFieldChange(e.target.value)}
                />
              </Grid>
            ) : (
              <Grid>
                <TextField
                  variant="outlined"
                  autoComplete="validation"
                  name="validation"
                  required
                  fullWidth
                  id="validation"
                  label={t("Password")}
                  autoFocus
                  value={validationCode}
                  onChange={validationChange}
                />
              </Grid>
            )}
          </div>
          
          <div style={{ display: error.display ? "" : "none", paddingTop: "20px" }}>
            <Alert severity="error">
              <AlertTitle>{error.title}</AlertTitle>

              {error.text}
            </Alert>
          </div>

          <div style={{ paddingTop: "35px" }}>
            <Button
              disabled={activeStep === 0}
              onClick={handleBack}
              className={classes.backButton}
            >
              {t("Back")}
            </Button>

            <Button variant="contained" color="primary" onClick={handleNext}>
              {t("Next")}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
