import React, { useState, useEffect } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

import { useTranslation } from "react-i18next";

// import Error from "./Error";
// import Loading from "./Loading";

const ViewDummy = (props) => {
  var { t } = useTranslation();
  
  let [searchParams] = useSearchParams(useLocation().search);
  let [projectId] = useState(searchParams.get("id"))
  console.log("project_id", projectId);

  // const [loading, setLoading] = useState(true);
  // const [error, setError] = useState("");

  // if (loading) return <Loading />;
  // if (error) return <Error error={error} />;

  return (
    <div>
    <center>
      <h1>{t("CheckEmail4readyImages")}</h1>
    </center>
  </div>
  );
};

export default ViewDummy;
